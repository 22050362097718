import React, { useState , useRef } from "react";
import './Layout.scss';
import { Outlet } from "react-router-dom";
import Nav from './Nav';
import MobileNav from './ＭobileNav'
import Banner from '../Component/Home/Banner'
import MemberWrap from '../Component/All/MemberWrap'
import SubNav from '../Component/Home/SubNav';
import TopHeader from "../Component/All/TopHeader";

const Layout = (status) => {
  const [needRefresh , setNeedRefresh] = useState(true)
  const [betRefresh , setBetRefresh] = useState(true)
  return (
    <>
      {/* <Nav /> */}
      {
        status.status === "2" && 
        <div className="fixBx">
          <TopHeader/>
          <Banner />
          {
              localStorage.getItem('access_token') ?
              <MemberWrap betRefresh={betRefresh} setBetRefresh={setBetRefresh} needRefresh={needRefresh}/>
              :
              <SubNav />
          }
        </div>
      }
     
      <Outlet/>
      <MobileNav />
     
    </>
  )
}

export default Layout