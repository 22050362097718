import React, { useState , useEffect, useCallback , useRef , useContext} from 'react'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { FormattedMessage } from "react-intl";
import Marquee from "react-fast-marquee";
import './All.scss';
import api from '../../api/api';
import { ErrorContext } from '../../Routes';

import icon1 from '../../images/coin.png';
import icon2 from '../../images/man.png';
import icon3 from '../../images/bell.png';
import logo from '../../images/logo/logo.jpg'
const gameSel = [
  {
    name : 'Kecepatan pembalap',
    id : 1
  }
]

const TopHeader = ({betRefresh , setBetRefresh , needRefresh}) => {
  const { ErrorMessage , currencyJudge } = useContext(ErrorContext);

  const [loadingMember, setLoadingMember] = useState(false)
  const [loadingAnn, setLoadingAnn] = useState(false)
  const [loadingRefresh, setLoadingRefresh] = useState(true)
  const [loadingBalance, setLoadingBalance] = useState(true)
  const [nowBalance, setNowBalance] = useState(true)

  const [hideAmount, setHideAmount] = useState(false)
  const [memberDetail , setMemberDetail] = useState()
  const [betAnnouncements , setBetAnnouncements] = useState([])
  const [systemAnnouncements , setSystemAnnouncements] = useState([])
  const [announcement , setAnnouncement] = useState([])



    const getData = async() =>{
      getMe();
      getAnn();

    }

    // getme
    const getMe = async() => {
      setLoadingMember(false)
        try {
        const res = await api.get('/member', {
            headers: {
            "x-platform-code": `${localStorage.getItem("x-platform-code")}`,
            "Authorization": `Bearer ${localStorage.getItem("access_token")}`,
            },
        });
        if (res.data.status) {
            setMemberDetail(res.data.data)
            localStorage.setItem("accName", res.data.data.response.id);
            localStorage.setItem("isAdmin", res.data.data.response.is_admin);          
        }
        } catch (err) {
        console.log(err)
        const code = err.response.status
        const msg = err.response.data.data.error
        ErrorMessage(code, msg)
        } finally{
          setLoadingMember(true)
        }
    }
    const getMeBalance = async() => {
      try {
      const res = await api.get('/member/balance', {
          headers: {
          "x-platform-code": `${localStorage.getItem("x-platform-code")}`,
          "Authorization": `Bearer ${localStorage.getItem("access_token")}`,
          },
      });
      if (res.data.status) {
        // setMemberDetail(...memberDetail , `balanc : ${res.data.data}`)
        setNowBalance(res.data.data);
        setLoadingBalance(false)
          // setMemberDetail(res.data.data)
          // localStorage.setItem("accName", res.data.data.response.id);
      }
      } catch (err) {
      console.log(err)
      const code = err.response.status
      const msg = err.response.data.data.error
      ErrorMessage(code, msg)
      }
  }

   // 取得公告
   const getAnn = async() => {
    setLoadingAnn(false)
    try {
      const res = await api.get(`/announcements?language=${localStorage.getItem("language")}`, {
        headers: {
          "x-platform-code": `${localStorage.getItem("x-platform-code")}`,
          "Authorization": `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      if (res.data.status) {
        // .. memenangkan hadiah .... pada ...
        res.data.data.bet_announcements.map((v)=>{
          setBetAnnouncements(prevState => [...prevState , `${v.account} memenangkan hadiah ${gameSel.filter((g)=> g.id === v.game_id)[0].name} pada ${v.amount}`])
        })
        setSystemAnnouncements(res.data.data.system_announcements)
        
      }
    } catch (err) {
      console.log(err)
      const code = err.response.status
      const msg = err.response.data.data.error
      ErrorMessage(code, msg)
    } finally {
      setLoadingAnn(true)
    }
   }

   const getAnnAgain = async() => {
    try {
      const res = await api.get('/announcements', {
        headers: {
          "x-platform-code": `${localStorage.getItem("x-platform-code")}`,
          "Authorization": `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      if (res.data.status) {
        setBetAnnouncements([])
        res.data.data.bet_announcements.map((v)=>{
          setBetAnnouncements(prevState => [...prevState , `${v.account} memenangkan hadiah ${gameSel.filter((g)=> g.id === v.game_id)[0].name} pada ${v.amount}`])
        })
        setSystemAnnouncements(res.data.data.system_announcements)
      }
    } catch (err) {
      console.log(err)
      const code = err.response.status
      const msg = err.response.data.data.error
      ErrorMessage(code, msg)
    }
   }




  return (
    <>
      {
        <div className='topHeaderBx'>
            <div className='topLogo'>
                <img src={localStorage.getItem("logo_image_link")} />
            </div>
            <div className='rURL'>
                永久域名 : 
                <a href={localStorage.getItem("logo_link")}  target="_blank"> {localStorage.getItem("logo_link")}</a>
               
            </div>
        </div>  
      }
    </>
  )
}

export default TopHeader