import React, { useState, useEffect , useRef , useContext } from "react";
import { useSelector , useDispatch , Provider} from 'react-redux';
import { FormattedMessage , useIntl } from "react-intl";
import api from '../api/api';
import { ErrorContext } from '../Routes';
import Page from '../Component/All/Page'
import Spinner from '../Component/Spinner/Spinner'
import { NavLink } from "react-router-dom";
import Banner from '../Component/Home/Banner'
import { useParams, useNavigate , useLocation } from "react-router-dom";
import { ReactComponent as HomeIcon } from '../Component/Icon/Home.svg'
import { ReactComponent as Charge } from '../Component/Icon/Charge.svg'
import { ReactComponent as Host } from '../Component/Icon/Host.svg'
import { ReactComponent as Revenue } from '../Component/Icon/Revenue.svg'
import { ReactComponent as Car } from '../Component/Icon/RaceCar.svg'
import { ReactComponent as Clock } from '../Component/Icon/Clock.svg'
import { ReactComponent as Dice } from '../Component/Icon/Dice.svg'
import { ReactComponent as Poker } from '../Component/Icon/Poker.svg'

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import { FreeMode, Navigation, Thumbs } from 'swiper/modules';

// GiRaceCar
import { GiRaceCar, GiAlarmClock, GiPokerHand, GiPerspectiveDiceSixFacesTwo } from 'react-icons/gi';
import RulePop from '../Component/Home/RulePop'
import ThirdWalletsPop from '../Component/All/ThirdWalletsPop'

import MemberWrap from '../Component/All/MemberWrap'
import FixBx from '../Hook/fixBx'
// import HomeIcon from '../Component/Icon/Icons';

import btn1 from '../images/front-page-lottery/lottery.svg';
import btn1a from '../images/front-page-lottery/lottery(click).svg';
import btn2 from '../images/front-page-lottery/Chess.png';
import btn2a from '../images/front-page-lottery/Chess(click).png';
import btn3 from '../images/front-page-lottery/Electronic games.svg';
import btn3a from '../images/front-page-lottery/Electronic-games(click).svg';
import btn4 from '../images/front-page-lottery/sport.svg';
import btn4a from '../images/front-page-lottery/sport(click).svg';
import btn5 from '../images/front-page-lottery/real-person.svg';
import btn5a from '../images/front-page-lottery/real-person(click).svg';
import leaveImg from '../images/activity/leave.png';

import game1 from '../images/front-page-lottery/racing-A.png';
import game2 from '../images/front-page-lottery/racing-B.png';
import game3 from '../images/front-page-lottery/racing-C.png';
import game4 from '../images/front-page-lottery/Always-color-A.png';
import game5 from '../images/front-page-lottery/Always-color-B.png';
import game6 from '../images/front-page-lottery/baccarat.png';

import '../CSS/Home.scss'
import '../CSS/common.scss'
import GameCard from '../Component/Home/GameCard'
import SubNav from '../Component/Home/SubNav';
import Swal from 'sweetalert2'



// import useSound from 'use-sound';
// import boopSfx from '../sound/mouse_click.mp3';

const Cards = [
  {
    key: 1,
    imageSrc: game1,
  },
]
const leftButtons = [
  {
    title: "彩票",
    src: btn1,
    aSrc:btn1a,
    value:1,
    homeName:"Lottery"
  },
  {
    title: "電子",
    src: btn3,
    aSrc:btn3a,
    value:2,
    homeName:"Electronic"
  },
  {
    title: "體育",
    src: btn4,
    aSrc:btn4a,
    value:5,
    homeName:"Sport"
  },
  {
    title: "真人",
    src: btn5,
    aSrc:btn5a,
    value:3,
    homeName:"RealPerson"
  },
]

// const throttle = (func, delay) => {
//   let lastTime = 0;
//   return (...args) => {
//     const now = Date.now();
//     if (now - lastTime >= delay) {
//       func(...args);
//       lastTime = now;
//     }
//   };
// };

const Home = () => {
  const intl = useIntl();
  const location = useLocation();
  const { ErrorMessage } = useContext(ErrorContext);
  const [open, setOpen] = useState(true);
  const [perpage , setPerpage] = useState(10)
  const [nowPage, setNowPage] = useState(1)
  const [gameType , setGameType] = useState(0)
  const [totalPage, setTotalPage] = useState(0)
  const [leftBtn , setLeftBtn] = useState('彩票'); 
  const [loading , setLoading] = useState(true);
  const [loadingMember , setLoadingMember] = useState(false);
  const [gameName , setGameName] = useState('');
  const [vipCS , setVipCS] = useState({});
  const [barsList , setBarsList] = useState([]);
  const [barItemsList , setBarItemsList] = useState([]);
  const [barType , setBarType] = useState(true);
  const [articleType , setArticleType] = useState(false);
  const [nowArticle , setNowArticle] = useState({
    content : '',
    image_link : ''
  });

  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  const [barNowPage, setBarNowPage] = useState(1)
  const [barTotalPage, setBarTotalPage] = useState(0)
  const [nowBarID , setNowBarID] = useState()
  const fixBxRef = useRef(null);
  const mainRef = useRef(null);
  const fixbarRef = useRef(null);
  // 引入上推function
  FixBx(fixBxRef,mainRef,loading,loadingMember,fixbarRef)
  const dispatch = useDispatch();
  const getGameList = useSelector(state => state.game.listGames)
  const CS_URL = `/vipcs`;
  //滾輪判定
  // const containerRef = useRef(null);
  const [isBottom, setIsBottom] = useState(false);
  const handleScrollToTop = () => {
    const container = document.getElementById('root');
    container.scrollTo(0, 0);
  };

  // const handleScroll = () => {
  //   const container = document.getElementById('root');
  //   if (!container) return;

  //   const { scrollTop, scrollHeight, clientHeight } = container;
  //   // 判断是否滚动到底部
  //   setIsBottom(scrollTop + clientHeight >= scrollHeight);
  //   if(scrollTop + clientHeight >= scrollHeight){
  //     console.log('底部')
      
  //     const buttonContainer = document.querySelector(".buttons");
  //     if (buttonContainer) {
  //       // 找到当前 .buttons 容器中的按钮
  //       const buttons = buttonContainer.querySelectorAll("button");
  //       const activeButton = Array.from(buttons).find(
  //         (button) => button.dataset.active === "true"
  //       );

  //       if (activeButton) {
  //         // 找到当前按钮的索引
  //         const currentIndex = Array.from(buttons).indexOf(activeButton);

  //         // 获取下一个按钮
  //         const nextButton = buttons[currentIndex + 1];
  //         if (nextButton) {
  //           // 触发点击事件
          
  //           setTimeout(()=>{
  //             // container.scrollTo(0 , 0);
  //           },500)
  //           setTimeout(()=>{
  //             // nextButton.click();
  //           },1000)
  //         } else {
  //           console.log("没有下一个按钮");
  //         }
  //       }
  //     }
  //   }
    
  // };



  // useEffect(() => {
  //   let atBottom = false; 
  //   let throttling = false; 
  //   let isTouching = false; 
  //   let startY = 0; 

  //   const handleScroll = () => {
  //     const container = document.getElementById("root");
  //     if (!container) return;

  //     const { scrollTop, scrollHeight, clientHeight } = container;

  //     if (scrollTop + clientHeight >= scrollHeight - 1) {
  //       atBottom = true;
  //     } else {
  //       atBottom = false; 
  //     }
  //   };

  //   const executeButtonClickLogic = () => {
  //     if (!throttling) {
  //       throttling = true;

  //       const buttonContainer = document.querySelector(".buttons");
  //       if (buttonContainer) {
  //         const buttons = buttonContainer.querySelectorAll("button");
  //         const activeButton = Array.from(buttons).find(
  //           (button) => button.dataset.active === "true"
  //         );

  //         if (activeButton) {
  //           const currentIndex = Array.from(buttons).indexOf(activeButton);
  //           const nextButton = buttons[currentIndex + 1];

  //           if (nextButton) {
  //             setTimeout(() => {
            
  //               nextButton.click(); 
  //               throttling = false; 
  //             }, 500);
  //           } else {
  //             throttling = false;
  //           }
  //         } else {
  //           throttling = false; 
  //         }
  //       }
  //     }
  //   };

  //   const handleTouchStart = (event) => {
  //     if (event.touches.length > 0) {
  //       startY = event.touches[0].clientY; 
  //       isTouching = true;
  //     }
  //   };

  //   const handleTouchMove = (event) => {
  //     if (!isTouching) return;

  //     if (event.touches.length > 0) {
  //       const currentY = event.touches[0].clientY;
  //       const deltaY = startY - currentY; 

  //       if (atBottom && Math.abs(deltaY) > 0) {
  //         executeButtonClickLogic();
  //       }

  //       startY = currentY; 
  //     }
  //   };

  //   const handleTouchEnd = () => {
  //     isTouching = false; 
  //   };

  //   const container = document.getElementById("root");
  //   if (container) {
  //     container.addEventListener("scroll", handleScroll);

  //     container.addEventListener("touchstart", handleTouchStart, { passive: true });
  //     container.addEventListener("touchmove", handleTouchMove, { passive: true });
  //     container.addEventListener("touchend", handleTouchEnd);
  //   }

  //   return () => {
  //     if (container) {
  //       container.removeEventListener("scroll", handleScroll);
  //       container.removeEventListener("touchstart", handleTouchStart);
  //       container.removeEventListener("touchmove", handleTouchMove);
  //       container.removeEventListener("touchend", handleTouchEnd);
  //     }
  //   };
  // }, [barItemsList]);


  useEffect(()=>{
    // dispatch({ type: 'SAGA_FETCH_LISTGAMES_API' , nowPage , perpage , setLoading , setTotalPage , gameType , gameName});
    getListGame()
    getGameChips()
  },[nowPage , gameType , gameName , location.pathname])
 
  useEffect(()=>{
    getVIPCSphone()
    getBarsList()
  },[])

  useEffect(()=>{
    // handleScrollToTop()
  },[barItemsList])

  // useEffect(()=>{
  //   getBarItemsList(nowBarID)
  // },[barNowPage])

  

  const getGameForward = (gameID) => {
    let timerInterval;
    Swal.fire({
      html: "跳转游戏中 , 请稍候",
      timerProgressBar: true,
      showConfirmButton: false,
      allowOutsideClick:false,
      timer : 5000
      // backdrop:false
    })
    dispatch({ type: 'SAGA_FETCH_GAMESFORWARD_API' , gameID , setLoading});
  }
  const getListGame = () =>{
    dispatch({ type: 'SAGA_FETCH_LISTGAMES_API' , nowPage , perpage , setLoading , setTotalPage , gameType , gameName});
  }

  const getGameChips = () =>{
    dispatch({ type: 'SAGA_FETCH_GAMECHIPS_API'});
  }

  // 拿客服電話
  const getVIPCSphone = async () => {
    try {
      const res = await api.get(CS_URL, {
        headers: {
          "x-platform-code": `${localStorage.getItem("x-platform-code")}`,
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      if (res.data.status) {
        console.log(res.data)
        setVipCS(res.data.data)
      }
    } catch (err) {
      console.log(err);
      const code = err.response.status
      const msg = err.response.data.data.error
      ErrorMessage(code, msg)
    }
  };
  // 拿分類列表
  const getBarsList = async () => {
    try {
      const res = await api.get(`/bars`, {
        headers: {
          "x-platform-code": `${localStorage.getItem("x-platform-code")}`,
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      if (res.data.status) {
        console.log(res.data)
        setBarsList(res.data.data.response)
        getBarItemsList(res.data.data.response[0].id)
        setLeftBtn(`${res.data.data.response[0].id}`)
        setNowBarID(res.data.data.response[0].id)
      }
    } catch (err) {
      console.log(err);
      const code = err.response.status
      const msg = err.response.data.data.error
      ErrorMessage(code, msg)
    }
  };
  // 拿分類項目列表
  const getBarItemsList = async (id) => {
    try {
      const res = await api.get(`/bars/${id}/items?page=${barNowPage}&per_page=${perpage}`, {
        headers: {
          "x-platform-code": `${localStorage.getItem("x-platform-code")}`,
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      if (res.data.status) {
        console.log(res.data)
        setBarItemsList(res.data.data.response)
        setBarTotalPage(Math.ceil(res.data.data.pagination.total / perpage))
        // handleScrollToTop()
      }
    } catch (err) {
      // const code = err.response.data.data.error
      // const msg = err.response.data.data.error_msg
      // ErrorMessage(code, msg)
    }
  };

  return (
    <div className="page">
     
      
      <div ref={mainRef}  className="main">
        <div className="homePage container  home-bg">

          <div className="homeBx flex">
            <div className="buttons">
              {/* {leftButtons.map((v) => {
                  return (
                    <button key={v.title} onClick={()=>{
                      setLeftBtn(v.title)
                      setGameType(v.value)
                      setBarType(false)
                    }}
                    data-active={leftBtn.includes(`${v.title}`)}
                    >
                      <div className="imgBx">
                        <img src={v.src}></img>
                        <img src={v.aSrc}></img>
                      </div>
                      
                      <div className="title">
                        <FormattedMessage
                              id={`home${v.homeName}`}
                        ></FormattedMessage>
                      </div>
                    </button>
                  )
              })}  */}
              {
                barsList.map((v)=>{
                  return (
                    <button key={v.id} onClick={()=>{
                      setLeftBtn(`${v.id}`)
                      setNowBarID(v.id)
                      getBarItemsList(v.id)
                      setBarType(true)
                      setArticleType(false)
                      setTimeout(()=>{
                        handleScrollToTop()
                      } , 300)
                     
                    }}
                    data-active={leftBtn.includes(`${v.id}`)}
                    >
                      <div className="imgBx">
                        <img src={v.image_link}></img>
                        <img src={v.click_image_link}></img>
                      </div>
                      
                      <div className="title">
                       {v.name}
                      </div>
                    </button>
                  )
                })
              }
            </div>
            {/* className={gameType === 1 || gameType === 3 || gameType === 0 ? "gamesWrap" : "gamesWrap fourList" */}
            <div className={"gamesWrap"}>
              {
                articleType ? 
                <div className="articleBx">
                  <div className="imgBx">
                    <img src={nowArticle.image_link} />
                  </div>
                  <div className="content" dangerouslySetInnerHTML={{ __html: nowArticle.content }}></div>
                  <button className="backBtn" onClick={()=>setArticleType(false)}>
                    <img src={leaveImg}></img>
                  </button>
                </div>
                :
                <div className="listBx">
                  {
                    loading ? 
                    <Spinner/> :
                    <>
                      {
                        barType || getGameList.data.response.map((v) =>{
                          return (
                            <>
                              <GameCard
                                key={v.image_link}
                                imageSrc={v.image_link}
                                cardData={v}
                                drawAt={getGameList.data.next_draw_at}
                                getGameForward={getGameForward}
                                getListGame={getListGame}
                                gameType={gameType}
                              />
                              {
                                // totalPage > 1 &&  
                                // <Page
                                //   totalPage={totalPage}
                                //   setNowPage={setNowPage}
                                //   nowPage={nowPage}
                                // />
                              }
                            </>
                          )
                        })
                      }
                      
                      {
                        barType && barItemsList.map((v) =>{
                          return (
                            // {
                            <>
                              {
                                v.type === 1 ?
                                  <div className="gameCard"
                                    onClick={() => {
                                      setArticleType(true)
                                      setNowArticle({
                                        content:v.article.content,
                                        image_link : v.article.image_link
                                      })
                                      // handleScrollToTop()
                                    }}>
                                    <img src={v.article.image_link}></img>
                                  </div>
                                  :
                                  <div className="gameCard"
                                    onClick={() => {
                                      getGameForward(v.game.game_id)
                                    }}>
                                    <img src={v.game.image_link}></img>
                                  </div>
                              }
                              {
                                // totalPage > 1 &&  
                                // <Page
                                //   totalPage={barTotalPage}
                                //   setNowPage={setBarNowPage}
                                //   nowPage={barNowPage}
                                // />
                              }
                            </>
                            // }
                            
                          )
                        })
                      }
                      {/* <div className="gameCard"
                          onClick={() => {
                            window.open(`${vipCS.cs}`, "_blank");
                          }}>
                          <img src={vipCS.image_link}></img>
                      </div> */}
                    </>
                    
                  }
                </div>
              }
              
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Home