import React, { useState , useEffect, useCallback , useRef , useContext} from 'react'
import { useSelector , useDispatch , Provider} from 'react-redux';
import moment from "moment";
import tz from "moment-timezone"
import Page from '../../Component/All/Page'
import Datep from '../../Component/All/Date'
import Spinner from '../Spinner/Spinner';
import ReplyIcon from '@mui/icons-material/Reply';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { FormattedMessage , useIntl } from "react-intl";

import Swal from 'sweetalert2'

import DatePicker, { registerLocale } from "react-datepicker";
import { subDays , addDays } from 'date-fns';
import 'react-datepicker/dist/react-datepicker.css';
import zhTW from "date-fns/locale/zh-TW"; 

import { ErrorContext } from '../../Routes';

registerLocale("zhTW", zhTW); 

var currentDate = new Date();
currentDate.setDate(currentDate.getDate());
currentDate.setHours(0, 0, 0, 0);

var endToDate = new Date();
endToDate.setHours(23, 59, 59, 999);

const CustomTimeInput = ({ date, onChangeCustom }) => {
    const value =
      date instanceof Date && !isNaN(date)
        ? // Getting time from Date beacuse `value` comes here without seconds
          date.toLocaleTimeString("it-IT")
        : "";
  
    return (
      <input
        type="time"
        step="1"
        value={value}
        onChange={(event) => onChangeCustom(date, event.target.value)}
      />
    );
};


const BettingRecord = ({setBoxSwitch}) => {
  const intl = useIntl();
  const { TimeMoment } = useContext(ErrorContext);

  const [loading , setLoading] = useState(true)
  const [perpage] = useState(15)
  const [nowPage, setNowPage] = useState(1)
  const [totalPage, setTotalPage] = useState(0)
  const [startDate, setStartDate] = useState(currentDate);
  const [endDate, setEndDate] = useState(endToDate);
  const [firstLoading , setFirstLoading] = useState(true)

  const [totalAmount , setTotalAmount] = useState(0)
  const [totalWin , setTotalWin] = useState(0)
  const [totalValidAmount , setTotalValidAmount] = useState(0)
  const [selType , setSelType]= useState('')
  const startRef = useRef()
  const endRef = useRef()

  //redux
  const dispatch = useDispatch();
  const betHistory = useSelector(state => state.betHistory.betHistory)

  const searchHandler = ( nowPage = 1 , perPage = 15) => {
    // event.preventDefault();
    //第一次loading讀取當天列表
    if(firstLoading){
        const startStamp = 0;
        const endStamp = 0;
        dispatch({ type: 'SAGA_FETCH_BETHISTORY_API' , nowPage , perpage , startStamp , endStamp , setLoading , setTotalPage , setTotalValidAmount , setTotalWin , setTotalAmount , selType});
        setFirstLoading(false)
    } else{
        const encodedStartTime = moment(startRef.current.props.selected).format('YYYY-MM-DD HH:mm:ss');
        const encodedEndTime = moment(endRef.current.props.selected).format('YYYY-MM-DD HH:mm:ss');
        const startStamp = parseInt(`${moment(encodedStartTime, 'YYYY-MM-DD HH:mm:ss').unix()}000`);
        const endStamp = parseInt(`${moment(encodedEndTime, 'YYYY-MM-DD HH:mm:ss').unix()}999`);
        dispatch({ type: 'SAGA_FETCH_BETHISTORY_API' , nowPage , perpage , startStamp , endStamp , setLoading , setTotalPage , setTotalValidAmount , setTotalWin , setTotalAmount , selType});
        
    }
  };

  useEffect(() => {
    searchHandler(nowPage,perpage)
  }, [nowPage,perpage,selType]);

  const field0 = [
    {
        name: intl.formatMessage({
            id: 'game.name',
        }),
        value : 1,
    },
    {
        name: intl.formatMessage({
            id: 'game.FourBallNameC',
        }),
        value : 107,
    },
    {
        name: intl.formatMessage({
            id: 'game.FourBallNameM',
        }),
        value : 108,
    },
    {
        name: intl.formatMessage({
            id: 'game.markSix',
        }),
        value : 109,
    },
    
  ]
  const field1 = [
    {
        name: intl.formatMessage({
            id: 'gameBtn.champion',
        }),
        value : "champion",
    },
    {
        name: intl.formatMessage({
            id: 'gameBtn.second',
        }),
        value : "second",
    },
    {
        name: intl.formatMessage({
            id: 'gameBtn.third',
        }),
        value : "third",
    },
    {
        name:intl.formatMessage({
            id: 'gameBtn.fourth',
        }),
        value : "fourth",
    },
    {
        name: intl.formatMessage({
            id: 'gameBtn.fifth',
        }),
        value : "fifth",
    },
    {
        name: intl.formatMessage({
            id: 'gameBtn.sixth',
        }),
        value : "sixth",
    },
    {
        name: intl.formatMessage({
            id: 'gameBtn.seventh',
        }),
        value : "seventh",
    },
    {
        name: intl.formatMessage({
            id: 'gameBtn.eighth',
        }),
        value : "eighth",
    },
    {
        name: intl.formatMessage({
            id: 'gameBtn.ninth',
        }),
        value : "ninth",
    },
    {
        name: intl.formatMessage({
            id: 'gameBtn.tenth',
        }),
        value : "tenth",
    },
    {
        name: intl.formatMessage({
            id: 'gameBtn.sp',
        }),
        value : "sp",
    },
    {
        name: intl.formatMessage({
            id: 'game.model4',
        }),
        value : "group",
    },
    {
        name: "1pk10",
        value : "1pk10",
    },
    {
        name: "2pk9",
        value : "2pk9",
    },
    {
        name: "3pk8",
        value : "3pk8",
    },
    {
        name: "4pk7",
        value : "4pk7",
    },
    {
        name: "5pk6",
        value : "5pk6",
    },
  ]
  const field2 = [
    {
        name: intl.formatMessage({
            id: 'game.big',
        }),
        value : "big",
    },
    {
        name: intl.formatMessage({
            id: 'game.small',
        }),
        value : "small",
    },
    {
        name: intl.formatMessage({
            id: 'game.odd',
        }),
        value : "odd",
    },
    {
        name: intl.formatMessage({
            id: 'game.even',
        }),
        value : "even",
    },
    {
        name: intl.formatMessage({
            id: 'game.big_even',
        }),
        value : "big_even",
    },
    {
        name: intl.formatMessage({
            id: 'game.small_even',
        }),
        value : "small_even",
    },
    {
        name: intl.formatMessage({
            id: 'game.big_odd',
        }),
        value : "big_odd",
    },
    {
        name: intl.formatMessage({
            id: 'game.small_odd',
        }),
        value : "small_odd",
    },
    {
        name: intl.formatMessage({
            id: 'game.banker',
        }),
        value : "banker",
    },
    {
        name: intl.formatMessage({
            id: 'game.player',
        }),
        value : "player",
    },
    {
        name: intl.formatMessage({
            id: 'game.dragon',
        }),
        value : "dragon",
    },
    {
        name: intl.formatMessage({
            id: 'game.tiger',
        }),
        value : "tiger",
    },
    {
        name: intl.formatMessage({
            id: 'game.fish',
        }),
        value : "fish",
    },
    {
        name: intl.formatMessage({
            id: 'game.shrimp',
        }),
        value : "shrimp",
    },
    {
        name: intl.formatMessage({
            id: 'game.crab',
        }),
        value : "crab",
    },
    {
        name: intl.formatMessage({
            id: 'game.rat',
        }),
        value : "rat",
    },
    {
        name: intl.formatMessage({
            id: 'game.ox',
        }),
        value : "px",
    },
    {
        name: intl.formatMessage({
            id: 'game.rabbit',
        }),
        value : "rabbit",
    },
    {
        name: intl.formatMessage({
            id: 'game.snake',
        }),
        value : "snake",
    },
    {
        name: intl.formatMessage({
            id: 'game.horse',
        }),
        value : "horse",
    },
    {
        name: intl.formatMessage({
            id: 'game.goat',
        }),
        value : "goat",
    },
    {
        name: intl.formatMessage({
            id: 'game.monkey',
        }),
        value : "monkey",
    },
    {
        name: intl.formatMessage({
            id: 'game.rooster',
        }),
        value : "rooster",
    },
    {
        name: intl.formatMessage({
            id: 'game.dog',
        }),
        value : "dog",
    },
    {
        name: intl.formatMessage({
            id: 'game.pig',
        }),
        value : "pig",
    },
  ] 
  const fourBallfield = [
    {
        name: intl.formatMessage({
            id: 'gameBtn.4Dp',
        }),
        value : "4Dp",
    },
    {
        name: intl.formatMessage({
            id: 'gameBtn.4Dn',
        }),
        value : "4Dn",
    },
    {
        name: intl.formatMessage({
            id: 'gameBtn.3Dp',
        }),
        value : "3Dp",
    },
    {
        name: intl.formatMessage({
            id: 'gameBtn.3Dp',
        }),
        value : "3Dn",
    },
    {
        name: intl.formatMessage({
            id: 'gameBtn.2Dpf',
        }),
        value : "2Dpf",
    },
    {
        name: intl.formatMessage({
            id: 'gameBtn.2Dpb',
        }),
        value : "2Dpb",
    },
    {
        name: intl.formatMessage({
            id: 'game.thousands',
        }),
        value : "thousands",
    },
    {
        name: intl.formatMessage({
            id: 'game.hundreds',
        }),
        value : "hundreds",
    },
    {
        name: intl.formatMessage({
            id: 'game.tens',
        }),
        value : "tens",
    },
    {
        name: intl.formatMessage({
            id: 'game.units',
        }),
        value : "units",
    },
    {
        name: intl.formatMessage({
            id: 'game.un_f',
        }),
        value : "un_f",
    },
    {
        name: intl.formatMessage({
            id: 'game.un_b',
        }),
        value : "un_b",
    },
    {
        name: intl.formatMessage({
            id: 'game.fsc',
        }),
        value : "fsc",
    },
  ]
  const sixMarkfield = [
    {
        name: intl.formatMessage({
            id: 'game.1st',
        }),
        value : "1st",
    },
    {
        name: intl.formatMessage({
            id: 'game.2nd',
        }),
        value : "2nd",
    },
    {
        name: intl.formatMessage({
            id: 'game.3rd',
        }),
        value : "3rd",
    },
    {
        name: intl.formatMessage({
            id: 'game.4th',
        }),
        value : "4th",
    },
    {
        name: intl.formatMessage({
            id: 'game.5th',
        }),
        value : "5th",
    },
    {
        name: intl.formatMessage({
            id: 'game.6th',
        }),
        value : "6th",
    },
    {
        name: intl.formatMessage({
            id: 'game.sp',
        }),
        value : "sp",
    },
    {
        name: intl.formatMessage({
            id: 'game.chineseZodiacTitle',
        }),
        value : "cz",
    },
    {
        name: intl.formatMessage({
            id: 'game.sp_chineseZodiacTitle',
        }),
        value : "sp_cz",
    },
  ]
  const vietGamefield = [
    {
        name: intl.formatMessage({
            id: 'game.2d_head',
        }),
        value : "2d_head",
    },
    {
        name: intl.formatMessage({
            id: 'game.2d_tail',
        }),
        value : "2d_tail",
    },
    {
        name: intl.formatMessage({
            id: 'game.2d_tail_contrary',
        }),
        value : "2d_tail_contrary",
    },
    {
        name: intl.formatMessage({
            id: 'game.2d_head_tail',
        }),
        value : "2d_head_tail",
    },
    {
        name: intl.formatMessage({
            id: 'game.2d_first_prize',
        }),
        value : "2d_first_prize",
    },
    {
        name: intl.formatMessage({
            id: 'game.2d_group',
        }),
        value : "2d_group",
    },
    {
        name: intl.formatMessage({
            id: 'game.2d_group_seven',
        }),
        value : "2d_group_seven",
    },
    {
        name: intl.formatMessage({
            id: 'game.3d_head',
        }),
        value : "3d_head",
    },
    {
        name: intl.formatMessage({
            id: 'game.3d_tail',
        }),
        value : "3d_tail",
    },
    {
        name: intl.formatMessage({
            id: 'game.3d_head_tail',
        }),
        value : "3d_head_tail",
    },
    {
        name: intl.formatMessage({
            id: 'game.3d_first_prize',
        }),
        value : "3d_first_prize",
    },
    {
        name: intl.formatMessage({
            id: 'game.3d_group',
        }),
        value : "3d_group",
    },
    {
        name: intl.formatMessage({
            id: 'game.3d_group_seven',
        }),
        value : "3d_group_seven",
    },
    {
        name: intl.formatMessage({
            id: 'game.3d_tail',
        }),
        value : "3d_tail",
    },
    {
        name: intl.formatMessage({
            id: 'game.4d_head',
        }),
        value : "4d_head",
    },
    {
        name: intl.formatMessage({
            id: 'game.4d_tail',
        }),
        value : "4d_tail",
    },
    {
        name: intl.formatMessage({
            id: 'game.4d_head_tail',
        }),
        value : "4d_head_tail",
    },
    {
        name: intl.formatMessage({
            id: 'game.4d_first_prize',
        }),
        value : "4d_first_prize",
    },
    {
        name: intl.formatMessage({
            id: 'game.4d_group',
        }),
        value : "4d_group",
    },
    {
        name: intl.formatMessage({
            id: 'game.4d_group_seven',
        }),
        value : "4d_group_seven",
    },




    {
        name: intl.formatMessage({
            id: 'game.2d_fixed_head',
        }),
        value : "2d_fixed_head",
    },
    {
        name: intl.formatMessage({
            id: 'game.3d_fixed_head',
        }),
        value : "3d_fixed_head",
    },
    {
        name: intl.formatMessage({
            id: 'game.4d_fixed_tail',
        }),
        value : "4d_fixed_tail",
    },
    {
        name: intl.formatMessage({
            id: 'game.2d_two_sides_head',
        }),
        value : "2d_two_sides_head",
    },{
        name: intl.formatMessage({
            id: 'game.3d_two_sides_head',
        }),
        value : "3d_two_sides_head",
    },
    {
        name: intl.formatMessage({
            id: 'game.4d_two_sides_tail',
        }),
        value : "4d_two_sides_tail",
    },{
        name: intl.formatMessage({
            id: 'game.match_2',
        }),
        value : "match_2",
    },
    {
        name: intl.formatMessage({
            id: 'game.match_3',
        }),
        value : "match_3",
    },{
        name: intl.formatMessage({
            id: 'game.match_4',
        }),
        value : "match_4",
    },
  ]
  const judgeField = (player_bet , num) =>{
    if(num === 1){
        var element = field1.find(item => item.value === player_bet.split(',').slice(0, 2)[0]);
        return element ? element.name : player_bet.split(',').slice(0, 2)[0];
    } else if (num ===2){
        var element = field2.find(item => item.value === player_bet.split(',').slice(0, 2)[1]);
        return element ? element.name : player_bet.split(',').slice(0, 2)[1];
    } else if (num ===3){
        var element = fourBallfield.find(item => item.value === player_bet.split(',').slice(0, 2)[0]);
        return element ? element.name : player_bet.split(',').slice(0, 2)[0];
    } else if (num ===4){
        var element = sixMarkfield.find(item => item.value === player_bet.split(',').slice(0, 2)[0]);
        return element ? element.name : player_bet.split(',').slice(0, 2)[0];
    } else if (num ===5){
        var element = vietGamefield.find(item => item.value === player_bet.split(',').slice(0, 2)[0]);
        return element ? element.name : player_bet.split(',').slice(0, 2)[0];
    } else if (num ===0){
        var element = field0.find(item => item.value === player_bet);
        return element.name
    }

    
    
  }
  const handleChangeTime = (date, time) => {
    const [hh, mm, ss] = time.split(":");
    const targetDate = date instanceof Date && !isNaN(date) ? date : new Date();
    targetDate.setHours(Number(hh) || 0, Number(mm) || 0, Number(ss) || 0);
    setStartDate(targetDate);
  };
  const handleChangeTimeEnd = (date, time) => {
    const [hh, mm, ss] = time.split(":");
    const targetDate = date instanceof Date && !isNaN(date) ? date : new Date();
    targetDate.setHours(Number(hh) || 0, Number(mm) || 0, Number(ss) || 0);
    setEndDate(targetDate);
  };
  const typeSel = [
    {
      name : '彩票',
      value : 1
    },
    {
      name : '电子',
      value : 2
    },
    {
      name : '真人视讯',
      value : 3
    },
    {
      name : '棋牌',
      value : 4
    },
    {
      name : '体育',
      value : 4
    },
  ]
  const changeTypeSel = (event) => {
    setSelType(event.target.value);
  }

  return (
    <div className="bettingRecordWrap bottWrap">
        <div className='titleFlex'>
            <button className='backBtn' onClick={()=>{
                setBoxSwitch(false)
            }}
            ><ReplyIcon/></button>
            
            <div>
                <FormattedMessage
                    id={`profile.bettingRecord`}
                ></FormattedMessage>
            </div>
        </div>
       
        <div className='dataFlex'>
            <div className='timeTitle'>
                <FormattedMessage
                    id={`game.time`}
                ></FormattedMessage>
            </div>
            <DateRangeIcon/>

            <button className='searchBtn' 
            onClick={() => {
                    if (startDate && endDate) {
                        searchHandler(1,15);
                        setNowPage(1);
                    } else {
                        Swal.fire({
                            icon: "warning",
                            text:intl.formatMessage({
                                id: 'alert.notSelected',
                            }),
                            showConfirmButton: true,
                        });
                    }
                }}>
                <FormattedMessage
                    id={`profile.inquire`}
                ></FormattedMessage>
        </button>
        </div>
        

        <Datep
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        startRef={startRef}
        endRef={endRef}
        />
        {/* <button className='searchBtn' 
            onClick={() => {
                    if (startDate && endDate) {
                        searchHandler(1,15);
                        setNowPage(1);
                    } else {
                        Swal.fire({
                            icon: "warning",
                            text:intl.formatMessage({
                                id: 'alert.notSelected',
                            }),
                            showConfirmButton: true,
                        });
                    }
                }}>
                <FormattedMessage
                    id={`profile.inquire`}
                ></FormattedMessage>
        </button> */}
        <select className='typeSel' onChange={changeTypeSel}  value={selType}>
            <option key={0} value={''}>筛选游戏类型</option>
            {
                typeSel.map((v)=>{
                    return (
                    <option key={v.name} value={v.value}>{v.name}</option>
                    )
                })
            }
        </select>
        
        <div className='listBx'>
            {loading === false ? 
                betHistory.data.pagination.total === 0 ? 
                <div className='notData'>
                    <FormattedMessage
                        id={`profile.noDataFoundToday`}
                    ></FormattedMessage>
                </div> :
                    <div>
                        <div className='scrollBx'>
                            <div className='titileBx'>
                                <div>
                                    <FormattedMessage
                                        id={`game.time`}
                                    ></FormattedMessage>
                                </div>
                                <div>
                                    <FormattedMessage
                                        id={`profile.gameName`}
                                    ></FormattedMessage>
                                </div>
                                <div>
                                    <FormattedMessage
                                        id={`game.issue`}
                                    ></FormattedMessage>
                                </div>
                                <div>
                                    <FormattedMessage
                                        id={`profile.content`}
                                    ></FormattedMessage>
                                </div>
                                <div>
                                    <FormattedMessage
                                        id={`profile.amount`}
                                    ></FormattedMessage>
                                </div>
                                <div>
                                    <FormattedMessage
                                        id={`profile.result`}
                                    ></FormattedMessage>
                                </div>
                                <div>
                                    有效投注
                                </div>
                            </div>
                            {  
                                <ul>
                                    {
                                        betHistory.data.response.map((v)=>{ 
                                            return (
                                                <li key={v.id}>
                                                    <div>{
                                                        TimeMoment(v.update_at)
                                                    }</div>
                                                    <div className='gameTitle'>{`${v.third_party_game_id !== '' && betHistory.data.third_party_game[v.third_party_game_id]}-${betHistory.data.game_name[v.game_id]}`}</div>
                                                    <div>{ v.game_round_round_num}</div>
                                                    <div>
                                                        {v.player_bet}
                                                    </div>
                                                    <div>{v.amount}</div>
                                                    <div>{v.win}</div>
                                                    <div>{v.valid_amount}</div>
                                                </li>
                                            )
                                        })
                                    }
                                    <li>
                                        <div>总计</div>
                                        <div></div>
                                        <div></div>
                                        <div></div>

                                        <div>{totalAmount}</div>
                                        <div>{totalWin}</div>
                                        <div>{totalValidAmount}</div>
                                    </li>
                                </ul>
                            }
                        </div>
                        {totalPage > 1 &&
                        <Page
                            totalPage={totalPage}
                            setNowPage={setNowPage}
                            nowPage={nowPage}
                        />}
                    </div>
                : <Spinner/> 
            }
        </div>
    </div>
  )
}

export default BettingRecord



